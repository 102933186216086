/*
* Fonts
*/

/* Add fonts to base styles */
@layer base {
    @font-face {
        font-family: 'Elizeth';
        src:
            url('../fonts/Elizeth-Light.woff2') format('woff2'),
            url('../fonts/Elizeth-Light.woff') format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }
}
