/* Grids */

:root {
    --grid-columns: 6;
    --grid-gap: 20px;
    --grid-margin: 30px;
    --grid-container: 475px;

    --grid-bleed: calc(var(--grid-margin) - var(--grid-gap));
    --grid-column-width: calc(
        (var(--grid-container) - (var(--grid-columns) - 1) * var(--grid-gap)) /
            var(--grid-columns)
    );
}

@screen sm {
    :root {
        --grid-columns: 12;
        --grid-gap: 40px;
        --grid-margin: 90px;
        --grid-container: 1400px;
    }
}

.full-grid {
    display: grid;
    grid-template-columns:
        minmax(var(--grid-bleed), 1fr)
        repeat(var(--grid-columns), minmax(0, var(--grid-column-width)))
        minmax(var(--grid-bleed), 1fr);
    column-gap: var(--grid-gap);
}

@layer utilities {
    .grid-gap-x {
        column-gap: var(--grid-gap);
    }
    .grid-span-full {
        grid-column: 2 / -2;
    }
    .grid-span-full-bleed {
        grid-column: 1 / -1;
    }
    .grid-span-8 {
        grid-column: span 8 / span 8;
    }
    .grid-span-1 {
        grid-column: span 1 / span 1;
    }
    .grid-span-2 {
        grid-column: span 2 / span 2;
    }
    .grid-span-3 {
        grid-column: span 3 / span 3;
    }
    .grid-span-4 {
        grid-column: span 4 / span 4;
    }
    .grid-span-5 {
        grid-column: span 5 / span 5;
    }
    .grid-span-6 {
        grid-column: span 6 / span 6;
    }
    .grid-span-7 {
        grid-column: span 7 / span 7;
    }
    .grid-span-8 {
        grid-column: span 8 / span 8;
    }
    .grid-span-9 {
        grid-column: span 9 / span 9;
    }
    .grid-span-10 {
        grid-column: span 10 / span 10;
    }
    .grid-span-11 {
        grid-column: span 11 / span 11;
    }
    .grid-span-12 {
        grid-column: span 12 / span 12;
    }
    .grid-span-13 {
        grid-column: span 13 / span 13;
    }

    .grid-bleed-left {
        grid-column-start: 1;
    }
    .grid-start-1 {
        grid-column-start: 2;
    }
    .grid-start-2 {
        grid-column-start: 3;
    }
    .grid-start-3 {
        grid-column-start: 4;
    }
    .grid-start-4 {
        grid-column-start: 5;
    }
    .grid-start-5 {
        grid-column-start: 6;
    }
    .grid-start-6 {
        grid-column-start: 7;
    }
    .grid-start-7 {
        grid-column-start: 8;
    }
    .grid-start-8 {
        grid-column-start: 9;
    }
    .grid-start-9 {
        grid-column-start: 10;
    }
    .grid-start-10 {
        grid-column-start: 11;
    }
    .grid-start-11 {
        grid-column-start: 12;
    }
    .grid-start-12 {
        grid-column-start: 13;
    }

    .grid-bleed-right {
        grid-column-end: -1;
    }
    .grid-end-1 {
        grid-column-end: 2;
    }
    .grid-end-2 {
        grid-column-end: 3;
    }
    .grid-end-3 {
        grid-column-end: 4;
    }
    .grid-end-4 {
        grid-column-end: 5;
    }
    .grid-end-5 {
        grid-column-end: 6;
    }
    .grid-end-6 {
        grid-column-end: 7;
    }
    .grid-end-7 {
        grid-column-end: 8;
    }
    .grid-end-8 {
        grid-column-end: 9;
    }
    .grid-end-9 {
        grid-column-end: 10;
    }
    .grid-end-10 {
        grid-column-end: 11;
    }
    .grid-end-11 {
        grid-column-end: 12;
    }
    .grid-end-12 {
        grid-column-end: 13;
    }

    .bleed-gap-right {
        margin-right: calc(var(--grid-gap) * -1);
    }
    .bleed-gap-left {
        margin-left: calc(var(--grid-gap) * -1);
    }
    .bleed-gap-x {
        margin-left: calc(var(--grid-gap) * -1);
        margin-right: calc(var(--grid-gap) * -1);
    }
}

@layer utilities {
    .main-column-gap {
        column-gap: var(--grid-gap);
    }
    .main-gap {
        gap: var(--grid-gap);
    }
}

.header-grid {
    grid-template-areas:
        'h-logo h-toggle'
        'h-search h-search';
    @screen sm {
        grid-template-columns: 11rem 1fr 63px;
        grid-template-areas: 'h-logo h-search h-toggle';
    }
}

.header-grid-logo {
    grid-area: h-logo;
}
.header-grid-toggle {
    grid-area: h-toggle;
}
.header-grid-search {
    grid-area: h-search;
}

.pageintro-grid {
    grid-template-rows: auto minmax(0, 1fr);
}

.external-teaser-grid {
    grid-template-columns: 2fr 0.3fr 0.7fr 2fr;
}
