/* Additional Utilities */

@layer utilities {
    /* Force an aspect ratio on an element */
    .aspect-box:before {
        content: '';
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
    }
    .aspect-box:after {
        content: '';
        display: table;
        clear: both;
    }
    .aspect-ratio-1-1:before {
        padding-top: 100%;
    }
    .aspect-ratio-16-9:before {
        padding-top: 56.25%;
    }
    .aspect-ratio-3-2:before {
        padding-top: 66.67%;
    }
    .aspect-ratio-4-3:before {
        padding-top: 75%;
    }
    .aspect-ratio-logo:before {
        padding-top: 48.9%;
    }
    .aspect-ratio-infoReelImage:before {
        padding-top: 73.1%;
    }
    .aspect-ratio-cssvar {
        padding-top: var(--aspect-ratio);
    }
}

/* Undo container style */
@layer utilities {
    .container-none {
        max-width: auto;
        margin-right: 0;
        margin-left: 0;
        padding-right: 0;
        padding-left: 0;
    }
}

/* Undo br */
@layer utilities {
    .br-none br {
        display: none;
    }
}

/* CSS Columns */
@layer utilities {
    .columns-2 {
        columns: 2;
    }
    .columns-3 {
        columns: 3;
    }
    .main-columns-gap {
        column-gap: var(--main-gap);
    }
}

.col-avoid-break-inside {
    -webkit-column-break-inside: avoid; /* Chrome, Safari */
    page-break-inside: avoid; /* Theoretically FF 20+ */
    break-inside: avoid-column; /* IE 11 */
    display: table; /* Actually FF 20+ */
}

/* Add a carret between items horizontally */
.divide-x-carret * + *:before {
    @apply pl-2 pr-1;
    content: '';
    width: 1.4em;
    height: 0.6em;
    background: url('../svg/icon-carret-right-dark.svg') center/contain
        no-repeat;
    display: inline-block;
}

.divide-x-carret-red * + *:before {
    background-image: url('../svg/icon-carret-right-red.svg');
}

/* Rotate an element relative to its containers's corner */
.rotate-corner-container {
    position: relative;
    .rotate-corner-top-right,
    .rotate-corner-top-left {
        position: absolute;
        top: 0;
    }
    .rotate-corner-top-right {
        right: 0;
        transform-origin: top right;
        transform: rotate(-90deg) translateY(-100%);
    }
    .rotate-corner-top-left {
        left: 0;
        transform-origin: top left;
        transform: rotate(-90deg) translateX(-100%);
    }
}

/* Tailwind not generating negative class for translate */
.-translate-x-14x10 {
    --tw-translate-x: -140%;
}

/* Change Autocomplete styles in Chrome*/
.input-autofill-bg-dark:-webkit-autofill,
.input-autofill-bg-dark:-webkit-autofill:hover,
.input-autofill-bg-dark:-webkit-autofill:focus {
    -webkit-text-fill-color: #ffffff;
    -webkit-box-shadow: 0 0 0px 1000px #44432f inset;
    transition: background-color 5000s ease-in-out 0s;
}

.scroll-mt-v-66 {
    scroll-margin-top: calc(theme('spacing.v-66'));
}

@layer utilities {
    .overflow-x-shadows {
        --background-color: rgba(255, 255, 255, 1);
        --background-color: rgba(255, 255, 255, 1);
        --shadow-color: rgba(34, 34, 34, 0.2);
        --shadow-size: 0.8em;
        --transparent: rgba(255, 255, 255, 0);

        background: linear-gradient(
                to right,
                var(--background-color),
                var(--background-color),
                var(--transparent) calc(var(--shadow-size) * 2)
            ),
            radial-gradient(
                farthest-side at 0 50%,
                var(--shadow-color),
                var(--transparent)
            ),
            linear-gradient(
                to left,
                var(--background-color),
                var(--background-color),
                var(--transparent) calc(var(--shadow-size) * 2)
            ),
            radial-gradient(
                    farthest-side at 100% 50%,
                    var(--shadow-color),
                    var(--transparent)
                )
                100%;
        background-color: var(--background-color);
        background-repeat: no-repeat;
        background-attachment: local, scroll, local, scroll;
        background-size: 100% 100%, var(--shadow-size) 100%, 100% 100%,
            var(--shadow-size) 100%;
    }
    .overflow-x-shadows-none {
        background: none;
    }
}
