/* Ornaments */

/* Clipped image frames */
/* https://tools.webdevpuneet.com/css-clip-path-generator/ */

.frame-pages {
    clip-path: polygon(
        0% 0%,
        57% 0%,
        57% 4%,
        87% 4%,
        87% 0%,
        100% 0%,
        100% 100%,
        0% 100%,
        0% 65%,
        4% 65%,
        4% 32%,
        0% 32%
    );
}
.frame-article-teasers {
    clip-path: polygon(
        0% 0%,
        100% 0%,
        100% 100%,
        0% 100%,
        0% 77%,
        4% 77%,
        4% 37%,
        0% 37%
    );
}
.frame-article-teasers-lg {
    clip-path: polygon(
        0% 0%,
        100% 0%,
        100% 100%,
        0% 100%,
        0% 77%,
        2% 77%,
        2% 37%,
        0% 37%
    );
}
.frame-people-header {
    clip-path: polygon(
        0% 0%,
        100% 0%,
        100% 100%,
        0% 100%,
        0% 57%,
        5% 57%,
        5% 7%,
        0% 7%
    );
}
.frame-text-media {
    clip-path: polygon(
        0% 0%,
        100% 0%,
        100% 100%,
        0% 100%,
        0% 37%,
        3% 37%,
        3% 6%,
        0% 6%
    );
}
.frame-service-index-header {
    clip-path: polygon(
        0% 0%,
        100% 0%,
        100% 100%,
        0% 100%,
        0% 88%,
        4% 88%,
        4% 48%,
        0% 48%
    );
}
.frame-arc {
    position: relative;
    &:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 101%;
        height: 101%;
        background: no-repeat url(../svg/frame-arc.svg);
        background-size: cover;
    }
}

.frame-article-teasers-featured {
    clip-path: polygon(
        0% 0%,
        60% 0%,
        60% 4%,
        90% 4%,
        90% 0%,
        100% 0%,
        100% 100%,
        0% 100%,
        0% 58%,
        4% 58%,
        4% 29%,
        0% 29%
    );
    @screen sm {
        clip-path: polygon(
            0% 0%,
            77% 0%,
            77% 4%,
            89% 4%,
            89% 0%,
            100% 0%,
            100% 100%,
            0% 100%,
            0% 68%,
            2% 68%,
            2% 34%,
            0% 34%
        );
    }
}

.frame-service-header {
    clip-path: polygon(
        0% 0%,
        10% 0%,
        10% 5%,
        89% 5%,
        89% 0%,
        100% 0%,
        100% 10%,
        98% 10%,
        98% 50%,
        100% 50%,
        100% 100%,
        0% 100%,
        0% 88%,
        2% 88%,
        2% 48%,
        0% 48%
    );
    @screen sm {
        clip-path: polygon(
            0% 0%,
            100% 0%,
            100% 10%,
            98% 10%,
            98% 50%,
            100% 50%,
            100% 100%,
            0% 100%,
            0% 88%,
            2% 88%,
            2% 48%,
            0% 48%
        );
    }
}

.frame-newsletter {
    clip-path: polygon(
        0% 0%,
        100% 0%,
        100% 100%,
        0% 100%,
        0% 22%,
        4% 22%,
        4% 10%,
        0% 10%
    );
    @screen sm {
        clip-path: polygon(
            0% 0%,
            77% 0%,
            77% 1.25rem,
            90% 1.25rem,
            90% 0%,
            100% 0%,
            100% 100%,
            0% 100%,
            0% 64%,
            2% 64%,
            2% 30%,
            0% 30%
        );
    }
}

.frame-ornament-cropped {
    background: url(../svg/ornament-dial-cropped-peach.svg) no-repeat right
        bottom / contain;
    padding-bottom: 6%;
    padding-right: 7%;
    @screen sm {
        padding-bottom: 7%;
        padding-right: 9%;
    }
}

.collage-buildings {
    position: relative;
    width: 56%;
    margin: auto;
    & > picture:nth-child(1) img {
        padding-top: 33%;
    }
    div:nth-child(2) {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 64%;
    }
    @screen sm {
        width: auto;
    }
}

/* Background Images */
.has-no-js,
.has-no-webp {
    .bg-tiledstones {
        background-image: url(../img/bg-tiledstones.jpg);
    }
    .bg-waves {
        background-image: url(../img/bg-waves.jpg);
    }
    .bg-concrete {
        background-image: url(../img/bg-concrete.jpg);
    }
    .bg-crater {
        background-image: url(../img/assets/crater.jpg);
    }
    .bg-teamTeasers-imageBlock {
        background-image: url(../img/assets/abstract-1-landscape.jpg);
    }
    .bg-teamTeasers-imageBlock-portrait {
        background-image: url(../img/assets/abstract-1-portrait.jpg);
    }
    .bg-circle-outline-red {
        background-image: url(../svg/ornament-circle-outline-red.svg);
    }
    .bg-mountain-bw {
        background-image: url(../img/assets/mountain-bw-sm.jpg),
            linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
        @screen sm {
            background-image: url(../img/assets/mountain-bw.jpg),
                linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
        }
    }
}

.has-webp {
    .bg-tiledstones {
        background-image: url(../img/bg-tiledstones.webp);
    }
    .bg-waves {
        background-image: url(../img/bg-waves.webp);
    }
    .bg-concrete {
        background-image: url(../img/bg-concrete.webp);
    }
    .bg-crater {
        background-image: url(../img/assets/crater.webp);
    }
    .bg-teamTeasers-imageBlock {
        background-image: url(../img/assets/abstract-1-landscape.webp);
    }
    .bg-teamTeasers-imageBlock-portrait {
        background-image: url(../img/assets/abstract-1-portrait.webp);
    }
    .bg-mountain-bw {
        background-image: url(../img/assets/mountain-bw-sm.webp),
            linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
        @screen sm {
            background-image: url(../img/assets/mountain-bw.webp),
                linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
        }
    }
}

@layer utilities {
    .bg-curves {
        background-image: url(../svg/ornament-curves.svg);
        background-repeat: no-repeat;
        background-size: auto 77%;
    }
    .bg-curves-light {
        background-image: url(../svg/ornament-curves-light.svg);
        background-repeat: no-repeat;
    }
    .bg-curves-people-header {
        background-position: right 0 top 2.6875rem;
        @screen md {
            background-size: 87% auto;
            background-position: top right 26%;
        }
    }
    .bg-curves-home-header {
        background-position: right bottom;
        background-size: 100% 86%;
    }
    .bg-infoReel {
        background-image: url(../svg/logo-vahura-monogram-full-peach.svg);
        background-position: right 11% top;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

/* Help the main content pull under the header */
.article-header-slideover-pull,
.article-header-slideover-push {
    --slideover: calc(0.5 * 100% * 0.96 + 43px);
    @screen sm {
        --slideover: calc(0.5 * 100% * 0.35 + 66px);
    }
}

.article-header-slideover-pull {
    margin-bottom: calc(var(--slideover) * -1);
}

.article-header-slideover-push {
    padding-top: var(--slideover);
}

.articleIndex-header-slideover-pull {
    --slideover: calc(0.5 * 100% * 1.04);
    @screen sm {
        --slideover: calc(0.35 * 100% * 0.35);
    }
    margin-bottom: calc(var(--slideover) * -1);
    padding-bottom: var(--slideover);
}
