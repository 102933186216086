/* Custom Components */

/* Fluid embed iframes */
.responsive-embed {
    position: relative;
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

/* Lazyloading images etc. */
.lazyload,
.lazyloading {
    transition: all 0.3s;
    opacity: 0.3;
    background: transparent no-repeat scroll center center;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='38' height='38' xmlns='http://www.w3.org/2000/svg' stroke='%23CCCCCC'%3E%3Cg transform='translate(1 1)' stroke-width='2' fill='none' fill-rule='evenodd'%3E%3Ccircle stroke-opacity='.5' cx='18' cy='18' r='18'/%3E%3Cpath d='M36 18A18 18 0 0 0 18 0'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite'/%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-size: auto 25%;
}
.lazyloaded {
    transition: all 0.3s;
    opacity: 1;
    background-image: none;
}

/* Frames */
.frame-body-figure {
    position: relative;
    &:before,
    &:after {
        @apply bg-theme-orange;
        display: block;
        position: absolute;
        top: 50%;
        content: '';
        transform: translateY(-50%);
        width: 4px;
        height: 44%;
    }
    &:before {
        left: 100%;
    }
    &:after {
        right: 100%;
    }
    @screen sm {
        &:before,
        &:after {
            width: 9px;
        }
    }
}

.reel {
    display: flex;
    &.overflowing {
        padding-bottom: 48px;
    }
    &::after {
        content: '';
        flex-basis: 32px;
        flex-shrink: 0;
    }
    &.reel-compact::after {
        flex-basis: 20px;
    }

    .slide {
        flex: 0 0 auto;
        width: 68.6vw;
        flex-basis: auto;
    }
    .slide + .slide {
        margin-left: 4vw;
    }
    &.reel-compact .slide + .slide {
        margin-left: 20px;
    }

    @screen sm {
        & > .slide {
            width: 287px;
        }
        & > .slide.slide-image {
            width: 21vw;
            max-width: 345px;
        }
        & > .slide.slide-image:nth-child(2n) {
            width: 28vw;
            max-width: 460px;
        }
        & > .slide.slide-teaser {
            width: 352px;
        }
        & > .slide.slide-info {
            width: 25vw;
        }
        & > .slide.slide-videoTeaser {
            width: 30vw;
            max-width: 400px;
        }
    }
    @screen md {
        &::after {
            flex-basis: 58px;
        }
        &.reel-compact::after {
            flex-basis: 40px;
        }
        &.reel-compact .slide + .slide {
            margin-left: 40px;
        }
        .slide.slide-info {
            width: 19vw;
        }
        .slide + .slide {
            margin-left: 4vw;
        }
    }
}

@screen md {
    .md\:no-reel {
        display: block;
        overflow: auto;
        & > .slide {
            width: auto;
        }
        & > .slide {
            flex: none;
        }
        & > .slide + .slide {
            margin-left: 0;
        }
    }
}

.has-no-js .reel,
.has-no-js .reel-y {
    --color-thumb: theme(colors.theme.orange.DEFAULT);
    --color-track: transparent;
    scrollbar-color: var(--color-thumb) var(--color-track);
    scrollbar-width: thin;
}

.has-no-js .reel {
    @apply pb-4;
    overflow-x: auto;
}

.has-no-js .reel-y {
    overflow-y: auto;
    scrollbar-color: var(--color-thumb) var(--color-track);
    scrollbar-width: thin;
}

.has-no-js .reel::-webkit-scrollbar {
    height: 8px;
}
.has-no-js .reel::-webkit-scrollbar-track {
    background-color: var(--color-track);
}
.has-no-js .reel::-webkit-scrollbar-thumb {
    background-color: var(--color-thumb);
}

/* Custom aspect ratios */
.aspect-ratio-teamTeasers:before {
    padding-top: 73%;
    @screen md {
        padding-top: 121%;
    }
}

/* Loading indicator */
.loading {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='38' height='38' xmlns='http://www.w3.org/2000/svg' stroke='%23CCCCCC'%3E%3Cg transform='translate(1 1)' stroke-width='2' fill='none' fill-rule='evenodd'%3E%3Ccircle stroke-opacity='.5' cx='18' cy='18' r='18'/%3E%3Cpath d='M36 18A18 18 0 0 0 18 0'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite'/%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
}

.link-cover {
    position: relative;
    .link-cover-source:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
