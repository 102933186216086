/* Formie CSS Variables */

/* This theming is in addition to the config set in _components/forms */
.fui-i {
    /* Base Typography */
    --fui-font: theme(fontFamily.prose);

    /* Theme */
    --fui-error-color: theme(colors.theme.orange.DEFAULT);

    /* Labels */
    --fui-label-font-weight: 600;
    --fui-label-font-size: theme(fontSize.sm);
    @screen md {
        --fui-label-font-size: theme(fontSize.base);
    }

    /* Inputs */
    --fui-input-font-size: theme(fontSize.base);
    --fui-input-padding: theme(spacing.3);
    @screen md {
        --fui-input-padding: theme(spacing.3) theme(spacing.5);
        --fui-input-font-size: theme(fontSize.md);
    }
    --fui-input-line-height: theme(lineHeight.normal);
    --fui-input-background-color: theme(colors.theme.beige.50);
    --fui-check-font-size: var(--fui-input-font-size);
    --fui-check-label-line-height: theme(lineHeight.normal);
    --fui-border-radius: theme(borderRadius.lg);
    --fui-submit-btn-border-color: transparent;

    /* Alerts */
    --fui-alert-padding: theme(spacing.4);
    --fui-alert-margin-bottom: theme(spacing.12);
    @screen md {
        --fui-alert-padding: theme(spacing.6);
        --fui-alert-margin-bottom: theme(spacing.16);
    }
    --fui-alert-font-size: var(--fui-input-font-size);
    --fui-alert-line-height: var(--fui-input-line-height);
    --fui-alert-error-bg-color: theme(colors.theme.beige.400);
    --fui-alert-error-color: theme(colors.theme.orange.DEFAULT);
    --fui-alert-success-bg-color: #f3faf7;
    --fui-alert-success-color: #03543f;
    --fui-label-error-color: theme(colors.theme.orange.DEFAULT);
    --fui-border-color: transparent;

    /* Buttons */
    --fui-repeater-add-btn-top: 0.8rem;
    --fui-repeater-add-btn-left: 0.8rem;
    --fui-repeater-add-btn-width: 1em;
    --fui-repeater-add-btn-height: 1em;
    --fui-repeater-remove-btn-border: 1px solid black;
    --fui-btn-container-margin: theme(spacing.6) 0 0;
    --fui-btn-margin: 0;

    /* Page Tabs */
    --fui-tabs-border-bottom: theme(colors.black) solid 1px;
    --fui-tabs-margin-bottom: theme(spacing.12);
    --fui-tabs-font-size: theme(fontSize.base);
    --fui-tab-active-bg-color: theme(colors.theme.gray.deep);
    --fui-tab-active-color: theme(colors.white);
    --fui-tab-active-border-bottom-color: transparent;
    --fui-tabs-border-bottom: 2px solid theme(colors.black);
}

.native-form {
    .fui-i {
        --fui-border-color: theme(colors.theme.beige.500);
        @screen md {
            --fui-label-margin: theme(spacing[2.5]);
        }
    }
}
