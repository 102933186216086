/* Simplebar: Custom JS scrollbars */
/* https://github.com/Grsmto/simplebar/tree/master/packages/simplebar */

@import 'simplebar/dist/simplebar.css';

[data-customscrollbar]::-webkit-scrollbar {
    display: none;
}

[data-customScrollbar] {
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
}

.simplebar-scrollbar::before {
    @apply bg-theme-orange;
    border-radius: 0;
    left: 0;
    right: 0;
}

.simplebar-scrollbar.simplebar-visible::before {
    opacity: 1;
}

/* Horizontal scrollbar */

.simplebar-track.simplebar-horizontal .simplebar-scrollbar::before {
    left: 0;
    right: 0;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    top: 0;
    height: 5px;
}
.simplebar-track.simplebar-horizontal:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #919191;
    z-index: -1;
}

.reel-wrapper-bleed-right .simplebar-wrapper {
    @apply grid-span-full-bleed;
}

.simplebar-track.simplebar-horizontal {
    height: 5px;
}

.reel-wrapper-bleed-right .simplebar-track.simplebar-horizontal {
    @apply grid-start-1 grid-span-6 sm:grid-start-3 sm:grid-span-10;
}

/* Vertical scrollbar */

.simplebar-track.simplebar-vertical {
    width: 5px;
}

.simplebar-track.simplebar-vertical:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 2px;
    width: 1px;
    height: 100%;
    background-color: #919191;
    z-index: -1;
}

.simplebar-reversed .simplebar-track.simplebar-vertical {
    right: auto;
    left: 0;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
    top: 0;
    bottom: 0;
}

.scroll-fade-y {
    --fade-t: 3rem;
    --fade-b: 100px;
}

.scroll-fade-y[data-customscrollbar] {
    margin-top: calc(var(--fade-t) * -1);
    /* margin-bottom: calc(var(--fade-b) * -1); */
    padding-top: var(--fade-t);
    padding-bottom: var(--fade-b);
}
.scroll-fade-y[data-customscrollbar]:before,
.scroll-fade-y[data-customscrollbar]:after {
    pointer-events: none;
    content: '';
    position: absolute;
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 60%);
}

.bg-theme-blue .scroll-fade-y[data-customscrollbar]:before,
.bg-theme-blue .scroll-fade-y[data-customscrollbar]:after {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        theme(colors.theme.blue) 60%
    );
}

.scroll-fade-y[data-customscrollbar]:after {
    bottom: 0;
    height: var(--fade-b);
}

.scroll-fade-y[data-customscrollbar]:before {
    height: var(--fade-t);
    top: 0;
    transform: rotate(180deg);
    z-index: 1;
}

.scroll-fade-y .simplebar-track.simplebar-vertical {
    top: var(--fade-t);
    bottom: var(--fade-b);
}
