/*
 * Base
 */

/* Typefaces */
body {
    @apply font-prose;
}

h1,
h2,
h3,
h4,
h5 {
    @apply font-title;
}

/* Links */
a,
a:visited {
    @apply text-inherit no-underline;
}
a:hover {
    @apply underline;
}
a.block-link {
    &:hover {
        @apply no-underline;
    }
}

/* Anchor Targets */
.anchor-target {
    a.anchor {
        &:before {
            @apply relative;
            content: '#';
            top: -0.1em;
            padding: 0 0.1em;
            font-family: monospace;
            font-size: 0.8em;
            font-weight: normal;
        }
        @apply absolute invisible h-full no-underline text-theme-gray;
        margin-left: -0.62em;
    }
    @apply relative;
}
.logged-in {
    .bg-black {
        .anchor-target {
            &:hover {
                @apply text-black;
            }
        }
    }
    .anchor-target {
        &:hover {
            a.anchor {
                @apply visible;
            }
        }
        &:not(.bg-black) {
            a.anchor {
                @apply bg-gray-100;
            }
            &:hover {
                @apply bg-gray-100;
            }
        }
    }
}
.title-underline-stubby {
    .anchor {
        left: 0;
    }
}
.anchor-target-id {
    &:before {
        content: '';
        display: block;
        /* height and margin calculated based on sticky header padding (pt-v-32 & pb-v-25) values */
        height: calc(theme('spacing.v-25') + theme('spacing.v-32'));
        margin: calc(-1 * theme('spacing.v-25') - theme('spacing.v-32')) 0 0;
        @screen sm {
            display: none;
        }
    }
}

/* Text Styles */
.title-huge {
    @apply text-3xl leading-squeeze font-prose font-bold text-balance;
    @apply md:text-5xl lg:text-6xl;
}
.title-alt {
    @apply text-3xl leading-tight font-title font-light;
    @screen md {
        @apply text-6xl;
    }
}
.title-headline {
    @apply text-2xl leading-tight font-title font-light;
    @screen md {
        @apply text-5xl;
    }
}
.title-2 {
    @apply text-lg leading-tight font-prose font-bold md:text-3xl;
}
.title-3 {
    @apply text-xl leading-normal font-title font-light;
    @screen md {
        @apply text-3xl;
    }
}
.title-4 {
    @apply text-lg leading-snug font-title font-light;
    @screen md {
        @apply text-2xl;
    }
}
.title-5,
.fui-heading {
    @apply text-md md:text-xl leading-snug font-prose font-bold;
}

.prose-body {
    @apply text-base leading-relaxed font-prose font-normal md:text-md;
}
.prose-quote {
    @apply text-md leading-relaxed font-title font-light;
    @screen md {
        @apply text-lg;
    }
}
.prose-caption {
    @apply text-sm leading-relaxed font-prose font-normal;
    @screen md {
        @apply text-base;
    }
}

.meta-name {
    @apply text-base leading-normal font-title font-light;
    @screen md {
        @apply text-md;
    }
}
.meta-tag {
    @apply text-sm leading-relaxed font-prose tracking-widest font-normal;
    @screen md {
        @apply text-base;
    }
}
.meta-data {
    @apply text-xs leading-normal font-prose font-normal md:text-sm;
}

/* Frills */
.quo {
    margin-left: -0.24em;
}
.dquo {
    margin-left: -0.44em;
}
.tquo {
    margin-left: -0.68em;
}

.title-slash,
.title-slash-compact {
    @apply relative pl-5;
    &:before {
        content: '';
        position: absolute;
        top: -0.1em;
        left: -0.2em;
        height: 1.45em;
        width: 0.68em;
        background: url('../svg/icon-slash.svg') bottom right no-repeat;
        background-size: cover;
    }
}
.title-slash-compact {
    @apply pl-[1em];
    &:before {
        left: 0em;
    }
}
.title-slash-small {
    @apply relative pl-[0.65em];
    &:before {
        content: '';
        position: absolute;
        top: 0.2em;
        left: 0;
        height: 0.9em;
        width: 0.45em;
        background: url('../svg/icon-slash.svg') bottom right no-repeat;
        background-size: cover;
    }
}

.title-slash-nav {
    &:before {
        top: 0.05em;
        left: -0.2em;
        height: 1.12em;
        width: 0.5em;
    }
}

.link-target {
    position: relative;
    padding-right: 3em;
    display: inline-block;
    &:hover:after {
        content: '';
        position: absolute;
        bottom: 0.3em;
        height: 1em;
        width: 2.3em;
        margin-left: 1em;
        background: url('../svg/icon-target-orange.svg') no-repeat left;
        background-size: contain;
    }
}

.link-target-side {
    @apply meta-data;
    position: relative;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    white-space: nowrap;
    &:hover {
        text-decoration: none;
    }
    &:before {
        @apply transition-transform;
        content: '';
        display: block;
        height: 1.5em;
        width: 4.1em;
        margin-top: 0.1em;
        margin-right: 1.5em;
        background: url('../svg/icon-target-orange-left.svg') no-repeat left;
        background-size: contain;
        flex: none;
    }
    &:hover:before {
        transform: translateX(1em);
    }
    &.link-target-side-black:before {
        background-image: url('../svg/icon-target-black-left.svg');
    }
}

.link-target-side-container {
    animation: link-target-side 1s 1.5;
}

@keyframes link-target-side {
    0%,
    100% {
        transform: translateX(1rem);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }

    50% {
        transform: none;
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}

.link-caret,
.group-hover\:link-caret {
    &:after {
        content: '';
        margin-left: 0.2em;
        padding-left: 0.6em;
        margin-right: -0.8em;
        background: url('../svg/icon-carret-right-light.svg') no-repeat left;
        background-size: contain;
        background-position: 0 0.39em;
    }
    &.link-caret-title:after {
        padding-left: 0.8em;
        margin-right: -1em;
        background-position: center 0.3em;
    }
    &.link-caret-orange:after {
        background-image: url('../svg/icon-carret-right-red.svg');
    }
}
.group:hover .group-hover\:link-caret:after,
.link-caret:hover:after {
    background-image: url('../svg/icon-carret-right-red.svg');
}

.dropdown-caret {
    position: relative;
    display: flex;
    align-items: center;
    &:after {
        content: '';
        height: 0.4em;
        width: 0.7em;
        background: url('../svg/icon-carret-bottom-small-black.svg') no-repeat
            right;
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: 0.4em;
        flex-shrink: 0;
        transition: transform 150ms;
    }
    &.dropdown-open:after {
        transform: rotate(180deg);
    }
    &.dropdown-caret-large-gray:after {
        background-image: url('../svg/icon-carret-bottom-large-gray.svg');
    }
    &.dropdown-caret-large-gray.dropdown-open:after {
        transform: rotate(180deg);
    }
}

.title-underline {
    @apply pb-6 relative;
    --title-underline-width: 107px;
    @screen md {
        --title-underline-width: 168px;
    }
    &:after {
        content: ' ';
        @apply absolute h-6;
        bottom: 0;
        left: 0;
        width: var(--title-underline-width);
        background: url('../img/ornament-underline.png') no-repeat left bottom;
        background-size: var(--title-underline-width) auto;
    }
    &.visible:after {
        animation: swoosh 0.5s;
    }
}
@keyframes swoosh {
    from {
        clip-path: inset(0 100% 0 0);
    }
    to {
        clip-path: inset(0 0 0 0);
    }
}

@layer utilities {
    .title-underline-stubby {
        display: flex;
        flex-direction: column;
    }
    .title-underline-stubby:after {
        @apply mt-v-18 md:mt-v-32 h-1 w-v-43 bg-theme-orange;
        content: '';
    }
    .title-underline-center {
        align-items: center;
    }
}

/* Lists */
.featured-ordered-list {
    counter-reset: featured-ordered-list;
    & li {
        counter-increment: featured-ordered-list;
    }
    & li::before {
        @apply title-5 mb-v-12 md:mb-v-25 bg-theme-orange;
        content: '/ ' counter(featured-ordered-list);
        display: flex;
        width: max-content;
        color: #fff;
        min-width: 2.5em;
        height: 2.5em;
        align-items: center;
        padding-left: 0.45em;
        padding-right: 0.45em;
        word-spacing: -0.07em;
        @screen md {
            min-width: 2.03em;
            height: 2.03em;
            padding-left: 0.3em;
        }
    }
}

.featured-ordered-list-compact,
.featured-unordered-list-compact {
    @apply list-none -ml-4;
    li {
        @apply relative pl-[1.8em] sm:pl-[2em] mb-[1.5em] sm:mb-[1.8em] last:mb-0;
    }
}

.featured-ordered-list-compact {
    counter-reset: featured-ordered-list;
    li {
        counter-increment: featured-ordered-list;
    }
    li::before {
        content: counter(featured-ordered-list);
        @apply absolute left-0
            flex items-center justify-center
            mb-0 translate-y-[0.2em]
            text-[0.8em] text-white bg-theme-orange;
        min-width: 1.5em;
        height: 1.5em;
        padding: 0.1em;
        word-spacing: 0;
    }
}

.featured-unordered-list-compact {
    @apply list-none;
    li::before {
        @apply absolute top-0 left-[0.7em] h-[1.45em] w-[0.68em];
        content: '';
        background: url('../svg/icon-slash.svg') bottom right no-repeat;
        background-size: cover;
    }
}

.list-heading {
    @apply block mb-[0.4em] font-title font-normal text-md md:text-lg;
    & + br {
        @apply hidden;
    }
}

/* Rich Text */
.richtext {
    @apply prose-body;

    :where(p, ul, ol) {
        @apply my-v-18;
    }
    :where(p:last-child, ul:last-child, ol:last-child) {
        @apply mb-0;
    }
    :where(p:first-child, ul:first-child, ol:first-child) {
        @apply mt-0;
    }

    h3 {
        @apply title-3 title-underline;
    }
    h4 {
        @apply title-4;
    }
    h5 {
        @apply title-5;
    }

    :where(ol, ul) {
        @apply pl-4;
    }
    :where(li) {
        @apply mb-2;
    }
    :where(li li) {
        @apply ml-2;
    }
    ol {
        list-style-type: decimal;
    }
    ul {
        list-style-type: disc;
    }
    li ul {
        list-style-type: circle;
    }
    ol ol {
        list-style-type: lower-alpha;
    }
    ol ol ol {
        list-style-type: lower-roman;
    }
    ol.list-none {
        list-style-type: none;
    }
    ul.list-none {
        list-style-type: none;
    }

    a {
        @apply text-theme-orange underline;
    }
    a.no-underline {
        text-decoration: none;
    }
    a.text-inherit {
        color: inherit;
    }

    strong {
        @apply font-bold;
    }
}

/* Buttons */
.cta {
    @apply px-5 py-3
        text-base md:text-md leading-tight font-prose font-normal
        rounded-lg bg-theme-orange no-underline;
    position: relative;
    padding-right: 5.3em;
    display: inline-block;
    color: theme(colors.white) !important;
    &:hover {
        @apply no-underline;
    }
    &:visited {
        @apply text-white;
    }
    &:after {
        @apply transition-transform;
        content: '';
        position: absolute;
        bottom: 0.6em;
        height: 1.2em;
        width: 3.1em;
        margin-left: 1em;
        right: 1.2em;
        background: url('../svg/icon-target.svg') no-repeat left;
        background-size: contain;
        transform: translateX(0);
    }
    &:hover:after {
        transform: translateX(-0.5em);
    }
    &.cta-outline {
        @apply bg-transparent border border-theme-orange;
        color: theme(colors.theme.orange.DEFAULT) !important;
    }
    &.cta-outline:after,
    &.cta-secondary:after {
        background-image: url('../svg/icon-target-orange.svg');
    }
    &.cta-compact {
        @apply py-2 px-5 rounded-md
            text-xs md:text-sm
            font-prose font-normal;
    }
    &.cta-compact:after {
        display: none;
    }
    &.cta-secondary {
        @apply text-theme-orange bg-transparent border-none pl-0;
        color: theme(colors.theme.orange.DEFAULT) !important;
    }
    &.cta-md {
        font-size: 1.375rem;
    }
    &.cta-md:after {
        bottom: 0.55em;
    }
}

.header-wrapper + * {
    margin-top: 0 !important;
}
