/* Animations */

/* Hamburger */
/* https://github.com/jonsuh/hamburgers */

.hamburger {
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    &:hover {
        opacity: 0.9;
    }
    &.is-active:hover {
        opacity: 0.9;
    }
    &.is-active .hamburger-inner,
    &.is-active .hamburger-inner::before,
    &.is-active .hamburger-inner::after {
        background-color: #000;
    }
}

.hamburger-box {
    width: 21px;
    height: 14px;
    display: inline-block;
    position: relative;
    @screen sm {
        width: 28px;
        height: 21px;
    }
}

.hamburger-inner {
    display: block;
    top: 50%;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    @apply bg-theme-gray-deep;
    width: 21px;
    height: 2px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
    @screen sm {
        height: 3px;
        width: 28px;
    }
    .hamburger-gray & {
        @apply bg-theme-gray;
    }
}

.hamburger-inner::before,
.hamburger-inner::after {
    content: '';
    display: block;
}
.hamburger-inner::before {
    top: -7px;
    @screen sm {
        top: -10px;
    }
}
.hamburger-inner::after {
    bottom: -7px;
    @screen sm {
        bottom: -10px;
    }
}
/*  */

.hamburger-squeeze {
    & .hamburger-inner {
        transition-duration: 0.075s;
        transition-timing-function: cubic-bezier(05, 0.055, 0.675, 0.19);
    }
    & .hamburger-inner::before {
        transition:
            top 0.075s 0.12s ease,
            opacity 0.075s ease;
    }
    & .hamburger-inner::after {
        transition:
            bottom 0.075s 0.12s ease,
            transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    &.is-active .hamburger-inner {
        @apply bg-theme-gray-pale;
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    &.is-active .hamburger-inner::before {
        @apply bg-theme-gray-pale;
        top: 0;
        opacity: 0;
        transition:
            top 0.075s ease,
            opacity 0.075s 0.12s ease;
    }
    &.is-active .hamburger-inner::after {
        @apply bg-theme-gray-pale;
        bottom: 0;
        transform: rotate(-90deg);
        transition:
            bottom 0.075s ease,
            transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
}

.animate-dial-spin {
    @apply animate-spin;
    animation-duration: 35s;
}

:root {
    --easeAnimate: cubic-bezier(0.645, 0.045, 0.355, 1);
    --transition: all 0.5s var(--easeAnimate);
}

.has-js .animate-zoom-out {
    --easeAnimate: cubic-bezier(0.61, 1, 0.88, 1);
    --transition: all 0.8s var(--easeAnimate);
    overflow: hidden;
    & .animate-inner {
        transform: scale(1.1);
        transition: var(--transition);
    }
    &.animate .animate-inner {
        transform: scale(1);
    }
    &.animate-slower {
        --transition: all 1s var(--easeAnimate);
    }
}

.has-js .animate-reveal-to-bottom {
    opacity: 0;
    transition: var(--transition);
    overflow: hidden;
    & .animate-inner {
        transform: scale(1.2);
        transition: var(--transition);
    }
    &.animate {
        opacity: 1;
        max-height: var(--max-height) !important;
        & .animate-inner {
            transform: scale(1);
        }
    }
    &.animate-slower {
        --transition: all 1s var(--easeAnimate);
    }
}

/* Navigation animations */

/* Staggered animation */
.animate-staggered {
    --anim-delay: 0.065s;
    --anim-duration: 0.4s;
    animation-duration: var(--anim-duration);
    animation-fill-mode: both;
    animation-timing-function: ease-out;
    animation-delay: calc(var(--anim-delay) * var(--anim-order));
}
@media print, (prefers-reduced-motion: reduce) {
    .animate-staggered {
        animation-duration: 1ms !important;
        transition-duration: 1ms !important;
        animation-iteration-count: 1 !important;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 25%, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
.animate-fadeInUp {
    animation-name: fadeInUp;
}

/* Get in Touch door */
.door-frame {
    @apply z-10 relative h-auto;
    margin-left: 30%;
    width: 65% !important;
}
.door-circle {
    @apply absolute z-20;
    width: 45% !important;
    top: 20%;
    left: 13.5%;
    transition-delay: 0.5s;
    transition: transform 1.5s ease;
    .has-js & {
        transform: translateX(-30%);
    }
    .has-js .animate & {
        transform: translateX(5%);
    }
    .has-js .animate & {
        -webkit-transform: translate3d(5%, 0, 1px);
    }
}
.door-right {
    @apply absolute z-0 top-0 w-auto h-full;
    --padding-right: 0px;
    @screen md {
        --padding-right: theme('spacing.10');
    }
    left: calc(30% - (0.3 * var(--padding-right)) + (100% * 12.5 / 239));
    top: 0.7847%;
    transition-delay: 0.5s;
    transition: transform 1.5s ease;
    &,
    .has-js .animate & {
        transform: perspective(1000px) rotateX(2.8deg) rotateY(-20deg);
    }
    .has-js & {
        transform-origin: right top;
        transform: none;
    }
}
