/* Dialog and iframe for PDFs and videos */

.dialog-iframe-container {
    @apply relative overflow-hidden w-[90vw] h-[80vh] clear-right;
}

.dialog-iframe-inner {
    @apply absolute inset-0 w-full h-full;
}

.dialog-iframe-close {
    @apply float-right mt-4 mb-2 p-3 rounded-full bg-theme-orange;
}
