/* Base */
html {
    @apply bg-white text-theme-gray-deep;
    scroll-behavior: smooth;
}
body {
    @apply leading-relaxed;
}

/* Defaults */
p {
    @apply mt-0;
}

/* Remove rounded border on iOS inputs */
input {
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
}

/* lazysizes auto width calculation */
picture img[data-sizes='auto'] {
    @apply block w-full;
}
picture img.w-auto[data-sizes='auto'] {
    width: auto;
}

/* Make sure elements hidden with alpine.js don't flash on load */
[x-cloak] {
    display: none !important;
}
.has-no-js [x-cloak] {
    display: block !important;
}
