/* Formie Overrides */

.fui-required {
    color: transparent;
}
.fui-required:after {
    content: '(required)';
    color: theme(colors.theme.gray.icon);
    font-size: 0.85em;
    font-weight: normal;
    margin-left: -0.5em;
}

.fui-loading.cta:after {
    background: none;
}

.download-resource-form {
    .fui-page-container,
    .fui-field-rows .fui-row {
        @apply grid;
    }
    .fui-field-rows .fui-row {
        @apply sm:grid-cols-2;
    }

    .fui-subfield-fieldset {
        @apply m-0 p-0;
    }

    .fui-input {
        @apply bg-theme-pink placeholder-theme-gray text-theme-gray appearance-none rounded-md;
        @apply meta-data w-full p-3;
    }

    .fui-btn {
        @apply cta mb-v-12 -mt-v-18;
    }

    .fui-sr-only {
        @apply sr-only;
    }

    /* Alert Messages */
    .fui-alert,
    .fui-alert-shadow {
        @apply mt-4 meta-data;
        @screen sm {
            @apply p-3 mt-0 mb-4;
        }
    }
    .fui-alert-success .fui-alert,
    .fui-alert-success {
        @apply text-md text-center text-theme-green-dark prose-body;
    }
    .fui-alert-success:before {
        @apply h-28 block mx-auto mb-4;
        content: '';
        background: url('../svg/icon-check-circle-large.svg') no-repeat center;
        background-size: auto 100%;
    }
    .fui-alert-error {
        @apply bg-gray-500 text-theme-beige;
    }
    .fui-error-message {
        @apply text-theme-orange meta-data;
    }
}

/* Hide Google re-captcha badge */
.grecaptcha-badge {
    display: none;
}

.native-form {
    .fui-btn {
        @apply focus:outline-1 focus:outline focus:outline-offset-2 focus:outline-black;
    }

    .fui-btn:not(.fui-submit, .fui-save, .fui-prev, .fui-repeater-remove-btn) {
        @apply text-black text-base leading-tight font-prose border-0
            bg-theme-beige-500 hover:bg-theme-beige-400;
    }

    .fui-alert {
        @apply border border-solid;
    }

    .fui-alert-error {
        @apply border-theme-orange;
    }

    .fui-alert-success {
        @apply text-black border-black bg-transparent;
    }

    .fui-fieldset .fui-label {
        font-weight: normal;
    }

    .fui-group,
    .fui-repeater-row {
        @apply border-none bg-theme-beige-400;
    }

    .fui-repeater-remove-btn {
        @apply bg-theme-beige-500 hover:bg-theme-beige-400 focus:border-none;
    }

    .fui-btn-wrapper {
        @apply flex-wrap justify-end gap-4;
    }

    .fui-tabs {
        @apply hidden sm:flex px-4 -mx-4 md:px-8 md:-mx-8;
    }
    .fui-heading {
        @apply mt-6 md:mt-10 -mb-2;
    }
}
